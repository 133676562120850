/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Grid, Flex, Box, Heading, Text, Link } from 'theme-ui';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../../plugins/storefront/src/components/Breadcrumbs';

const HowToChooseColorPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  return (
    <>
      <Helmet
        title={t({
          id: `GuidesColor.Title`,
          message: `How to choose color`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `GuidesColor.MetaDescription`,
            message: `How to choose a hair extensions color`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            collectionTitle={t({
              id: `GuidesColor.BreadcrumbsParentTitle`,
              message: `Guides`,
            })}
            collectionPath={'/guides'}
            productTitle={t({
              id: `GuidesColor.BreadcrumbsTitle`,
              message: `How to choose color`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="GuidesColor.Header">How to choose color</Trans>
        </Heading>
      </Box>

      <Grid
        columns={[1, 2]}
        mx="auto"
        px={[3, 2]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 960,
        }}
      >
        <Box>
          <GatsbyLink to="/guides/choose-hair-color">
            <Heading
              as="h2"
              variant="link"
              color="primary"
              my={[3, 4]}
              sx={{ fontSize: [3, 4], textAlign: 'center' }}
            >
              <Trans id="GuidesColor.Option1_Header">
                Choose by photos and videos
              </Trans>
            </Heading>
          </GatsbyLink>
          <GatsbyLink to="/guides/choose-hair-color">
            <Box sx={{ textAlign: 'center' }} mb={[3, 4]}>
              <StaticImage
                alt={t({
                  id: `GuidesColor.Option1_ImageAlt`,
                  message: `Choose the color watching our photos and videos`,
                })}
                placeholder="blurred"
                layout="constrained"
                src="../../images/guides/how-to-choose-color-option-1.jpg"
                sx={{ maxHeight: 300 }}
              />
            </Box>
          </GatsbyLink>
          <Trans id="GuidesColor.Option1_Description">
            <Link as={GatsbyLink} to="/guides/choose-hair-color" mb={[2, 3]}>
              Check out the photos and video with all our colors, keep in mind
              that the color should be matched to the ends of your hair.
            </Link>
          </Trans>
        </Box>

        <Box>
          <GatsbyLink to="/guides/send-hair-photos">
            <Heading
              as="h2"
              variant="link"
              color="primary"
              mt={[4, 5]}
              my={[3, 4]}
              sx={{ fontSize: [3, 4], textAlign: 'center' }}
            >
              <Trans id="GuidesColor.Option2_Header">Send your photos</Trans>
            </Heading>
          </GatsbyLink>
          <GatsbyLink to="/guides/send-hair-photos">
            <Box sx={{ textAlign: 'center' }} mb={(3, 4)}>
              <StaticImage
                alt={t({
                  id: `GuidesColor.Option2_ImageAlt`,
                  message: `Send a photos of your hair to us`,
                })}
                placeholder="blurred"
                layout="constrained"
                src="../../images/guides/how-to-choose-color-option-2.jpg"
                sx={{ maxHeight: 300 }}
              />
            </Box>
          </GatsbyLink>
          <Trans id="GuidesColor.Option2_Description">
            <Link as={GatsbyLink} to="/guides/send-hair-photos" mb={[2, 3]}>
              Send a photos of your hair to us. We will suggest the most
              suitable color to buy.
            </Link>
          </Trans>
        </Box>
      </Grid>
    </>
  );
};

export const HowToChooseColorPageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;
export default HowToChooseColorPage;
